<template>
  <div class="formError">
    <font-awesome-icon
      v-bind:icon="['fas', 'circle-xmark']"
      class="formError__icon"
    />

    <span>
      <slot />
    </span>
  </div>
</template>

<style lang="scss" scoped>
@import "@/scss/lib";

.formError {
  @include label;
  display: flex;
  margin: 0 0 rem(20px) 0;
  padding: rem(16px);
  color: $color-error-dark;
  background: $color-error-light;
  border-radius: rem(6px);
}

.formError__icon {
  margin: 2px rem(8px) 0 0;
  font-size: rem(16px);
}
</style>