<template>
  <div class="container prolong-contract">
    <div class="row">
      <div class="col">
        <p
          v-if="!hasExpired"
          v-html-safe="$t('prolong.bodyWillExpire', { name: member.name, endDate: formatDate(contract.end_date) })"
          class="prolong-contract__body"
        />

        <p
          v-if="hasExpired"
          v-html-safe="$t('prolong.bodyExpired', { name: member.name })"
          class="prolong-contract__body"
        />

        <i18n-t
          keypath="prolong.bodyCustomerService"
          scope="global"
          tag="p"
          class="prolong-contract__body"
        >
          <template v-slot:action>
            <a href="mailto:info@fitchannel.com">
              {{ $t('prolong.bodyCustomerServiceLinkLabel') }}
            </a>
          </template>
        </i18n-t>
      </div>

      <div class="col col--products">
        <div class="prolong-contract__reactiveTitle">
          {{ $t(`prolong.${hasExpired ? 'reactivateTitleExpired' : 'reactivateTitleWillExpire'}`) }}
        </div>

        <p class="prolong-contract__reactiveBody">
          {{ $t('prolong.reactivateBody') }}
        </p>

        <prolong-products />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import moment from 'moment';
import ProlongProducts from '@/components/ProlongProducts';

export default {
  components: {
    ProlongProducts,
  },

  props: {
    hasExpired: {
      type: Boolean,
      required: true,
    },

    trainingInfo: {
      type: Object,
      required: true,
    },

    contract: {
      type: [Object],
      required: true,
    },
  },

  computed: {
    ...mapState('member', ['member']),
  },

  methods: {
    formatDate(date) {
      return moment(date).format('DD-MM-YYYY');
    },
  },
};
</script>

<style lang="scss">
@import "@/scss/lib";

.prolong-contract__body {
  margin: 0 0 rem(20px) 0;

  &:last-child {
    margin: 0;
  }

  a {
    color: $color-text-link;
    text-decoration: underline;
  }
}
</style>

<style lang="scss" scoped>
@import "@/scss/lib";

.prolong-contract__body {
  @include paragraph;
}

.prolong-contract__reactiveTitle {
  @include paragraph--bold;
  margin: 0 0 rem(4px) 0;
}

.prolong-contract__reactiveBody {
  @include paragraph;
  margin: 0 0 rem(24px) 0;
}
</style>