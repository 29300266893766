<template>
  <div v-bind:class="{
    [blockName]: true,
    [modifierClasses]: true,
  }">
    <slot />
  </div>
</template>

<script>
import modifiers from '@/mixins/modifiers';

const columnWidths = [
  ...(new Array(12).fill(null).map((value, index) => `xs-${index + 1}`)),
  ...(new Array(12).fill(null).map((value, index) => `sm-${index + 1}`)),
  ...(new Array(12).fill(null).map((value, index) => `md-${index + 1}`)),
];
const columnOffsets = [
  ...(new Array(12).fill(null).map((value, index) => `xs-offset-${index + 1}`)),
  ...(new Array(12).fill(null).map((value, index) => `sm-offset-${index + 1}`)),
  ...(new Array(12).fill(null).map((value, index) => `md-offset-${index + 1}`)),
];
const columnOrder = [
  ...(new Array(12).fill(null).map((value, index) => `sm-order-${index + 1}`)),
  ...(new Array(12).fill(null).map((value, index) => `md-order-${index + 1}`)),
];

export default {
  mixins: [
    modifiers([
      ...columnWidths,
      ...columnOffsets,
      ...columnOrder,
    ]),
  ],

  data() {
    return {
      blockName: 'baseColumn',
    };
  }
}
</script>

<style lang="scss" scoped>
@import "@/scss/lib";

.baseColumn {
  padding: #{$gridGutterWidth / 2};
  width: 100%;

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    padding-bottom: 0;
  }

  &:only-child {
    padding: 0 #{$gridGutterWidth / 2};
  }

  @media (min-width: 768px) {
    padding: 0 #{$gridGutterWidth / 2};
  }

  @for $i from 1 through 12 {
    &--xs-#{$i} {
      @include getColumnsWidth($i);
    }

    &--xs-offset-#{$i} {
      margin-left: (100% / 12) * $i;
    }
  }

  @for $i from 1 through 12 {
    @media (min-width: 768px) {
      &--sm-#{$i} {
        @include getColumnsWidth($i);
      }

      &--sm-offset-#{$i} {
        margin-left: (100% / 12) * $i;
      }

      &--sm-order-#{$i} {
        order: #{$i};
      }
    }
  }

  @for $i from 1 through 12 {
    @media (min-width: 1024px) {
      &--md-#{$i} {
        @include getColumnsWidth($i);
      }

      &--md-offset-#{$i} {
        margin-left: (100% / 12) * $i;
      }

      &--md-order-#{$i} {
        order: #{$i};
      }
    }
  }
}
</style>