<template>
  <div v-bind:class="{
    [blockName]: true,
    [modifierClasses]: true,
  }">
    <slot />
  </div>
</template>

<script>
import modifiers from '@/mixins/modifiers';

export default {
  mixins: [
    modifiers(['stretch']),
  ],

  data() {
    return {
      blockName: 'baseRow',
    };
  }
}
</script>

<style lang="scss" scoped>
@import "@/scss/lib";

.baseRow {
  margin: 0 -#{$gridGutterWidth / 2};

  @media(min-width: 768px) {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    &--stretch {
      align-items: stretch;
    }
  }
}
</style>