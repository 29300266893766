<template>
  <section class="baseSection">
    <slot />
  </section>
</template>

<style lang="scss" scoped>
.baseSection {
  padding: 16px 0;

  &:first-child {
    padding-top: 0;
  }
}
</style>