<template>
  <div class="prolongPage">
    <base-section>
      <base-grid modifiers="maxWidth">
        <base-row>
          <base-column>
            <div
              v-bind:class="{
                'ex-member': true,
                'ex-member--loading': loading,
              }"
            >
              <logo-loader v-if="loading" />

              <div v-if="!loading">
                <base-heading type="2">
                  {{ $t('prolong.title') }}
                </base-heading>

                <prolong-contract
                  v-if="contract.extendable"
                  v-bind:has-expired="contract.status === 'inactive'"
                  v-bind:contract="contract"
                  v-bind:training-info="trainingInfo"
                />

                <div v-else>
                  <p>
                    {{ $t('prolong.errorProlong') }}
                  </p>

                  <a
                    target="_blank"
                    href="https://fitchannel.com/contact"
                    class="button button--secondary"
                  >
                    {{ $t('prolong.customerServiceLinkLabel') }}
                  </a>
                </div>

                <div class="general-section">
                  <base-heading type="4">
                    {{ $t('prolong.feedbackTitle') }}
                  </base-heading>

                  <p>
                    {{ $t('prolong.feedbackBody') }}
                    <br /><br />

                    {{ $t('prolong.feedbackNote') }}
                    <br />

                    <a href="mailto:info@fitchannel.com">
                      info@fitchannel.com
                    </a>
                  </p>

                  <br />

                  <div class="images">
                    <img
                      class="image"
                      v-bind:src="require('@/assets/prolong/Verlengen-1.jpg')"
                    />

                    <img
                      class="image"
                      v-bind:src="require('@/assets/prolong/Verlengen-2.jpg')"
                    />

                    <img
                      class="image"
                      v-bind:src="require('@/assets/prolong/20190517-800x450.jpg')"
                    />
                  </div>
                </div>
              </div>
            </div>
          </base-column>
        </base-row>
      </base-grid>
    </base-section>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import BaseSection from '@/components/grid/BaseSection';
import BaseGrid from '@/components/grid/BaseGrid';
import BaseRow from '@/components/grid/BaseRow';
import BaseColumn from '@/components/grid/BaseColumn';
import ProlongContract from '@/components/ProlongContract';
import LogoLoader from '@/components/LogoLoader';
import BaseHeading from '@/components/BaseHeading';

export default {
  components: {
    BaseSection,
    BaseGrid,
    BaseRow,
    BaseColumn,
    LogoLoader,
    ProlongContract,
    BaseHeading,
  },

  data() {
    return {
      trainingInfo: {},
      loading: true,
    };
  },

  computed: {
    ...mapState('order', ['contract']),
  },

  created() {
    this.getTrainingData();
  },

  methods: {
    ...mapActions('user', ['getContractTrainingData']),

    async getTrainingData() {
      try {
        this.loading = true;
        this.trainingInfo = await this.getContractTrainingData();
      } catch (err) {
        //
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.prolongPage {
  padding: 20px 0;
}
</style>

<style lang="scss">
@import "@/scss/lib";

.ex-member {
  &--loading {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }

  .button {
    max-width: 375px;
  }

  .paragraph {
    color: #666;
  }
}

.prolong-contract {
  .row {
    display: flex;

    @include mobile {
      flex-direction: column;
    }

    .col {
      display: flex;
      flex-direction: column;
      margin: 0 32px 0 0;
      padding: 20px 0 0;
      width: 100%;

      &:last-child {
        margin: 0;
      }
    }

    .col--products {
      background: $color-beige;
      border-radius: 6px;
      padding: 20px 16px;
    }
  }
}

.general-section {
  margin: 32px 0 0;
  padding: 20px 16px;
  border: 2px solid #f3f6f9;
  border-radius: 6px;

  .images {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    max-height: 250px;

    img {
      display: flex;
      max-height: 100%;
      max-width: calc(100% / 3 - 6px);
      object-fit: cover;
      border-radius: 6px;
    }
  }
}
</style>
