<template>
  <div v-bind:class="{
    [blockName]: true,
    [modifierClasses]: true,
  }">
    <slot />
  </div>
</template>

<script>
import modifiers from '@/mixins/modifiers';

export default {
  mixins: [
    modifiers(['maxWidth']),
  ],

  data() {
    return {
      blockName: 'baseGrid',
    };
  }
}
</script>

<style lang="scss" scoped>
@import "@/scss/lib";

.baseGrid {
  padding: 0 #{$gridGutterWidth / 2};

  &--maxWidth {
    margin: 0 auto;
    max-width: 1080px;
  }
}
</style>